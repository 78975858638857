export function initFormValidators() {
	window.addEventListener("load", () => {
		if (typeof $$epiforms !== "undefined" && $$epiforms && window.epi?.EPiServer?.Forms) {
			$$epiforms.extend(true, epi.EPiServer.Forms, {
				/// extend the Validator to validate Visitor's value in Clientside.
				Validators:
				{
					"Coloplast.Direct.Core.Features.Forms.Validators.SharepointCharacterLimitFieldValidator": function () {
						// this function is used to validate visitor's data value in ViewMode
						return { isValid: true };
					}
				}
			});
		}
	})
}
