import { track_form_submission } from 'shared/services/tracking/formTrackingService';
import Cookie from 'js-cookie'
import { cookieNames } from 'shared/settings/constants';

export function initFormTracker(form) {
	const
		formTitleElement = form.querySelector('.Form__Title'),
		formGTMName = form.name ? form.name : 'no_data';
	let
		formTitle = formTitleElement ? formTitleElement.innerText : 'no_data';
		formTitle = form.name ? form.name : formTitle;

    // tracking of form elements
	let elementsAllowedForTracking = JSON.parse(`{ "fields": ${form.dataset.fieldsallowedfortracking.replace(/'/g, '"')} }`);

	function formChangeHandler() {
		if (form.querySelector('.ValidationFail')) {
            Cookie.remove(cookieNames.FORM_SUBMIT_STATUS);
        }
		else if (form.querySelector('.Form__Success__Message') || form.querySelector('.Form__MainBody') && form.querySelector('.Form__MainBody').style.display == 'none') {
            try {
				track_form_submission(formGTMName);
            }
            catch(err) {
				console.error(err);
			}
            Cookie.remove(cookieNames.FORM_SUBMIT_STATUS);
        }
    }

    const formObserver = new MutationObserver(formChangeHandler);
	formObserver.observe(form, { childList: true, subtree: true, attributes: true });

    // form submit
    const submitBtn = form.querySelector('[type="submit"]');
	// Prevent error when no submit button is present
	if (submitBtn) {
		submitBtn.addEventListener('click', () => {
			// store the values that is to be tracked in a cookie because a form may redirect to another page on a successful submit. In that
			// case, the page that the user is redirected will pick up the cookie and track accordingly - and delete the cookie
			// Now start by building the field name/value list
			const invalidFields = form.querySelectorAll('.ValidationFail');
			//when submit remove all marked as invalid fields from previous submission to track if event is success and no redirect to success page or success popup
			[...invalidFields].forEach((field) => {
				field.classList.remove('ValidationFail');
			});
			let fieldStrings = [];
			elementsAllowedForTracking.fields.forEach((field) => {
				let element = form.querySelector(`[name="${field.ElementId}"]`);
				if (element) {
					fieldStrings.push(`${field.FriendlyName}: ${element.value}`);
				}
			});
			let payload = {
				eventcategory: `${formTitle} form interaction`,
				eventaction: `${formTitle} submitted (${fieldStrings.join(', ')})`,
				formsubmission: formGTMName,
			}
			Cookie.set(cookieNames.FORM_SUBMIT_STATUS, JSON.stringify(payload));
		});
	}
}
