import "./episerverForms.scss";
import { initFormValidators } from "./episerverFormsValidators";
import { initFormTracker } from "./episerverFormsTracker";

const forms = [...document.querySelectorAll('[data-f-type="form"]')];

if (forms.length > 0) {
	initFormValidators();

	forms.forEach((form) => {
		initFormTracker(form);
	});
}
